class Circle {
    constructor(radius, x, y) {
        this.radius = radius;
        this.x = x;
        this.y = y;
    }

    draw(context) {
        context.beginPath();
        context.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
        context.fill();
    }
}

class Rectangle {
    constructor(width, height, x, y) {
        this.width = width;
        this.height = height;
        this.x = x;
        this.y = y;
    }

    draw(context) {
        context.beginPath();
        context.rect(this.x, this.y, this.width, this.height);
        context.fill();
    }
}

class Triangle {
    constructor(base, height, x, y) {
        this.base = base;
        this.height = height;
        this.x = x;
        this.y = y;
    }

    draw(context) {
        context.beginPath();
        context.moveTo(this.x, this.y);
        context.lineTo(this.x + this.base / 2, this.y - this.height);
        context.lineTo(this.x - this.base / 2, this.y - this.height);
        context.closePath();
        context.fill();
    }
}

class Polygon {
    constructor(canvasElement, initialVertices = 5, radius = 100) {
        this.canvas = canvasElement;
        this.ctx = this.canvas.getContext('2d');
        this.vertices = initialVertices;
        this.radius = radius;
        this.centerX = this.canvas.width / 2;
        this.centerY = this.canvas.height / 2;
        this.angles = this.calculateAngles();
        this.vertexDragging = -1; // 标识正在拖拽的顶点索引
        this.lastMousePosition = { x: 0, y: 0 };
        this.isMouseOverPolygon = false;
        this.bindEvents();
        this.drawPolygon();
    }

    calculateAngles() {
        return [...Array(this.vertices)].map((_, index) => {
            return index * (2 * Math.PI) / this.vertices;
        });
    }

    bindEvents() {
        this.canvas.addEventListener('mousedown', this.handleMouseDown.bind(this));
        this.canvas.addEventListener('mousemove', this.handleMouseMove.bind(this));
        this.canvas.addEventListener('mouseup', this.handleMouseUp.bind(this));
    }

    handleMouseDown(e) {
        const rect = this.canvas.getBoundingClientRect();
        const mouseX = e.clientX - rect.left;
        const mouseY = e.clientY - rect.top;

        this.isMouseOverPolygon = this.isPointInPolygon(mouseX, mouseY);
        if (this.isMouseOverPolygon) {
            this.handleVertexDragStart(mouseX, mouseY);
        }
    }

    handleMouseMove(e) {
        const rect = this.canvas.getBoundingClientRect();
        const mouseX = e.clientX - rect.left;
        const mouseY = e.clientY - rect.top;

        if (this.vertexDragging !== -1) {
            this.handleVertexDragging(mouseX, mouseY);
        } else {
            this.isMouseOverPolygon = this.isPointInPolygon(mouseX, mouseY);
            this.drawPolygon(this.isMouseOverPolygon);
        }
    }

    handleMouseUp() {
        this.vertexDragging = -1;
    }

    handleVertexDragStart(mouseX, mouseY) {
        this.lastMousePosition = { x: mouseX, y: mouseY };
        this.canvas.addEventListener('mousemove', this.handleVertexDragging.bind(this));
        this.canvas.addEventListener('mouseup', this.handleVertexEndDragging.bind(this));
    }

    handleVertexDragging(mouseX, mouseY) {
        const dx = mouseX - this.lastMousePosition.x;
        const dy = mouseY - this.lastMousePosition.y;

        const newAngle = this.getAngleFromPoint(this.lastMousePosition.x + dx, this.lastMousePosition.y + dy);
        const index = this.getClosestVertexIndex(newAngle);

        this.angles[index] = newAngle;
        this.lastMousePosition = { x: mouseX, y: mouseY };
        this.drawPolygon();
    }

    handleVertexEndDragging() {
        this.canvas.removeEventListener('mousemove', this.handleVertexDragging);
        this.canvas.removeEventListener('mouseup', this.handleVertexEndDragging);
    }

    getAngleFromPoint(x, y) {
        const dx = x - this.centerX;
        const dy = y - this.centerY;
        const angle = Math.atan2(dy, dx) * (180 / Math.PI);
        return (angle < 0) ? (angle + 360) : angle;
    }

    getClosestVertexIndex(angle) {
        let closestIndex = 0;
        let minDiff = Infinity;

        for (let i = 0; i < this.angles.length; i++) {
            const diff = Math.abs(angle - (this.angles[i] - 90)); // 减去90度是为了调整极坐标的起始角度
            if (diff < minDiff) {
                minDiff = diff;
                closestIndex = i;
            }
        }

        return closestIndex;
    }

    isPointInPolygon(x, y) {
        let inside = false;
        for (let i = 0, j = this.vertices - 1; i < this.vertices; j = i++) {
            const xi = this.centerX + this.radius * Math.cos(this.angles[i]);
            const yi = this.centerY + this.radius * Math.sin(this.angles[i]);
            const xj = this.centerX + this.radius * Math.cos(this.angles[j]);
            const yj = this.centerY + this.radius * Math.sin(this.angles[j]);

            const intersect = ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
        }

        return inside;
    }

    drawPolygon(highlight = false) {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.ctx.beginPath();
        for (let i = 0; i < this.vertices; i++) {
            const x = this.centerX + this.radius * Math.cos(this.angles[i]);
            const y = this.centerY + this.radius * Math.sin(this.angles[i]);
            if (i === 0) {
                this.ctx.moveTo(x, y);
            } else {
                this.ctx.lineTo(x, y);
            }
        }
        this.ctx.closePath();
        const strokeColor = highlight ? 'red' : 'black';
        this.ctx.stroke();

        // Draw vertices
        this.ctx.fillStyle = 'red';
        for (let i = 0; i < this.vertices; i++) {
            const x = this.centerX + this.radius * Math.cos(this.angles[i]);
            const y = this.centerY + this.radius * Math.sin(this.angles[i]);
            this.ctx.beginPath();
            this.ctx.arc(x, y, 5, 0, 2 * Math.PI);
            this.ctx.fill();
        }
    }
}

export {
    Circle,
    Rectangle,
    Triangle,
    Polygon
}