<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <el-card class="stationPlanview">
            <poster-editor class="content-main"></poster-editor>
            <!-- <div style="height: 100px;"></div>
            <div class="a-line-t-e0 footerBox">
                <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">立即提交</el-button>
                <el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="cancelSubmit">&nbsp;&nbsp;&nbsp;返回&nbsp;&nbsp;&nbsp;</el-button>
            </div> -->
        </el-card>
    </div>
</template>
<script>
import PosterEditor from '../components/formcy/poster-editor.vue';
export default {
    components: {
        PosterEditor
    },
    data() {
        return {
        
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>
    .stationPlanview{
        font-size: 14px;
        height: 100%;
        overflow-y: auto;
        /deep/ .el-card__body{
            height: 100% !important;
            .content-main{
                height: 100%;
            }
        }
    }
</style>