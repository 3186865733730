<template>
    <div class="a-flex-rfsfs" style="align-items: stretch;">
        <div class="tools a-flex-cfsfs">
            <div 
                class="a-flex-rfsc tools-item" 
                :class="drwaId == item.id ? 'tools-item-ac' : ''" 
                v-for="(item,index) in tools" 
                :key="index" 
                @click="chooseDrawItem(item)">
                <img class="tools-icon" :src="item.icon" alt="">
                <span>{{ item.name }}</span>
            </div>
        </div>
        <div class="content a-flex-1 a-flex-rcc">
            <canvas class="posterCanvas" id="posterCanvas" ref="posterCanvas"></canvas>
        </div>
        <div class="property a-flex-cfsfs">
            <div class="a-flex-rsbc property-item">
                <span class="property-item-title">颜色</span>
                <el-color-picker v-model="currentItem.color" show-alpha></el-color-picker>
            </div>
            <div class="a-flex-rsbc property-item">
                <span class="property-item-title">背景颜色</span>
                <el-color-picker v-model="currentItem.bgColor" show-alpha></el-color-picker>
            </div>
            <div class="a-flex-rsbc property-item">
                <span class="property-item-title">宽度</span>
                <el-input v-model="currentItem.width" placeholder="请输入宽度"></el-input>
            </div>
            <div class="a-flex-rsbc property-item">
                <span class="property-item-title">高度</span>
                <el-input v-model="currentItem.height" placeholder="请输入高度"></el-input>
            </div>
            <div class="a-flex-rsbc property-item">
                <span class="property-item-title">X</span>
                <el-input v-model="currentItem.x" placeholder="请输入X坐标"></el-input>
            </div>
            <div class="a-flex-rsbc property-item">
                <span class="property-item-title">Y</span>
                <el-input v-model="currentItem.y" placeholder="请输入Y坐标"></el-input>
            </div>
        </div>
        <le-upload-file-img 
            v-show="false" 
            :uploadUrl="$Config.apiUrl.uploadFile" 
            :acceptArr="['.png','.jpg','.jpeg']" 
            notnull 
            ref="pic" 
            :limit="999"
            label="" 
            @handleSuccess="chooseImgSuccess"
            v-model="currentImg"></le-upload-file-img>
    </div>
</template>

<script>
import { Polygon } from '../../../utils/draw.js'
export default {
    data() {
        return {
            ctx: null,
            canvas: null,
            tools: [
                {
                    name: '图片',
                    id: 1,
                    icon: 'https://hichar-file.oss-cn-shanghai.aliyuncs.com/app/pc/tools-img.png'
                },{
                    name: '多边形',
                    id: 2,
                    icon: 'https://hichar-file.oss-cn-shanghai.aliyuncs.com/app/pc/tools-rectangle.png'
                },{
                    name: '圆形',
                    id: 3,
                    icon: 'https://hichar-file.oss-cn-shanghai.aliyuncs.com/app/pc/tools-round.png'
                },{
                    name: '箭头',
                    id: 4,
                    icon: 'https://hichar-file.oss-cn-shanghai.aliyuncs.com/app/pc/tools-arrow.png'
                },{
                    name: '直线',
                    id: 5,
                    icon: 'https://hichar-file.oss-cn-shanghai.aliyuncs.com/app/pc/tools-line.png'
                }
            ],
            drwaArr: [],
            currentItem: {
                id: '',
                width: 100,
                height: 100,
                x: 0,
                y: 0,
                bgColor: '',
                color: ''
            },
            drwaId: '',
            currentImg: ''
        };
    },

    mounted() {
        this.init()
    },

    methods: {
        
        init () {
            const w = 600, h = 600

            this.canvas = this.$refs.posterCanvas;      
            this.ctx = this.canvas.getContext('2d');    

            this.canvas.width = w * devicePixelRatio
            this.canvas.height = h * devicePixelRatio

            this.canvas.style.width = w + 'px'
            this.canvas.style.height = h + 'px'
        },
        chooseDrawItem (datas) {
            this.drwaId = datas.id
            switch (this.drwaId) {
                case 1:
                    this.$refs['pic'].$refs["upload"].$refs["upload-inner"].handleClick();
                    break;
                case 2:
                    // 创建 PolygonDrawer 实例
                    const polygonDrawer = new Polygon(this.canvas);
                    // 开始绘制多边形
                    polygonDrawer.startDrawing();
                    break;
                case 3:
                
                    break;
                case 4:
                
                    break;
                case 5:
                
                    break;
                default:
                    break;
            }
        },
        chooseImgSuccess (datas) {
            const image = new Image();      
            // 设置图片的宽度和高度与Canvas相同      
            // 首先需要知道图片的实际尺寸      
            image.onload = () => {        
                this.canvas.width = image.width * devicePixelRatio;        
                this.canvas.height = image.height * devicePixelRatio;        

                this.canvas.style.width = image.width + 'px'
                this.canvas.style.height = image.height + 'px'
                // 绘制图片到Canvas上        
                this.ctx.drawImage(image, 0, 0);     
                this.drwaArr.push({
                    id: this.drwaId,
                    width: image.width * devicePixelRatio,
                    height: image.height * devicePixelRatio,
                    x: 0,
                    y: 0,
                    bgColor: '',
                    color: ''
                }) 
                console.log(this.drwaArr);
            };      
            // 设置图片的源URL      
            image.src = datas;
        }
    },
};
</script>

<style lang="scss" scoped>
    .tools{
        width: 100px;
        height: 100%;
        overflow-y: scroll;
        .tools-item{
            width: 100%;
            padding: 0 12px;
            font-size: 14px;
            color: #333333;
            height: 50px;
            line-height: 50px;
            border-radius: 6px;
        }
        .tools-item-ac{
            background: #f2f2f2;
        }
        .tools-item:hover{
            background: #f2f2f2;
        }
        .tools-icon{
            width: 20px;
            height: 20px;
            margin-right: 12px;
        }
    }
    .content{
        overflow: scroll;
        .posterCanvas{
            background: #f2f2f2;
            border-radius: 8px;
        }
    }
    .property{
        width: 200px;
        height: 100%;
        overflow-y: scroll;
        .property-item{
            width: 100%;
            padding: 0 12px;
            font-size: 14px;
            color: #333333;
            height: 50px;
            line-height: 50px;
            .property-item-title{
                margin-right: 12px;
                white-space: nowrap;
            }
        }
    }
    .tools::-webkit-scrollbar {
        /*width: 0;宽度为0隐藏*/
        width: 0 !important;
        height: 0 !important;
    }
    .content::-webkit-scrollbar {
        /*width: 0;宽度为0隐藏*/
        width: 0 !important;
        height: 0 !important;
    }
    .property::-webkit-scrollbar {
        /*width: 0;宽度为0隐藏*/
        width: 0 !important;
        height: 0 !important;
    }
</style>